import { Link } from "gatsby"
import React, { Component } from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Button from "../components/shared/button"

const Details = styled.details.attrs(props => ({
  className: "p-10 mb-10",
}))`
  border-radius: 10px;
  &:focus {
    outline: 0;
  }
  > summary {
    cursor: pointer;

    &:after {
      float: right;
      content: "+";
    }
  }
  &[open] summary:after {
    content: "-";
  }
  > summary::-webkit-details-marker {
    display: none;
  }
  > summary:focus {
    outline: 0;
  }
`
class Faq extends Component {
  render() {
    return (
      <Layout>
        <SEO title="Frequently Asked Questions" />
        <div className="container mx-auto flex flex-wrap justify-center p-5">
          <div className="w-full">
            <Details className="max-w-full bg-white rounded overflow-hidden shadow-lg">
              <summary className="font-semibold text-xl lg:text-2xl">
                How do you qualify each carrier?
              </summary>
              <p className="text-lg leading-normal pt-5">
                We have a rigorous process to make sure they are fully insured,
                have been in business long enough to qualify as part of quality
                carrier program. In addition to our internal carrier ranking, we
                are integrated with multiple carrier reporting platforms to get
                live updates on any carriers performance.
              </p>
            </Details>
          </div>
          <div className="w-full">
            <Details className="max-w-full bg-white rounded overflow-hidden shadow-lg">
              <summary className="font-semibold text-xl lg:text-2xl">
                How do you qualify each driver?
              </summary>
              <p className="text-lg leading-normal pt-5">
                On top of ranking and qualifying carriers, we are also ranking
                each driver thanks to our mobile app. Each driver is required to
                use our mobile app. As such, we record their phone numbers. When
                they decide to quit one carrier and jump ship to another
                carrier, we will always know where they are if they are ever to
                be assigned on one of Archerhub’s loads. As soon as a bad
                driver’s phone number is assigned, our system will redflag the
                phone number and will not allow to tender the load to this
                driver.
              </p>
            </Details>
          </div>
          <div className="w-full">
            <Details className="max-w-full bg-white rounded overflow-hidden shadow-lg">
              <summary className="font-semibold text-xl lg:text-2xl">
                How do I quote a shipment?
              </summary>
              <p className="text-lg leading-normal pt-5">
                Just enter the shipment details in our mobile app or online
                platform and you will get instant quotes delivered to you
                immediately. If you have additional requirements to discuss
                please email us at{" "}
                <a
                  className="text-blue-600 hover:text-blue-700 active:text-blue-800"
                  href="mailto:shipper@archerhub.com"
                >
                  shipper@archerhub.com
                </a>
              </p>
            </Details>
          </div>
          <div className="w-full">
            <Details className="max-w-full bg-white rounded overflow-hidden shadow-lg">
              <summary className="font-semibold text-xl lg:text-2xl">
                What additional information do I need to provide after booking a
                shipment?
              </summary>
              <p className="text-lg leading-normal pt-5">
                If there is any additional information required, one of our
                representatives will reach out to you after you book the
                shipment.
              </p>
            </Details>
            <Details className="max-w-full bg-white rounded overflow-hidden shadow-lg">
              <summary className="font-semibold text-xl lg:text-2xl">
                How will I get updates about my shipment?
              </summary>
              <p className="text-lg leading-normal pt-5">
                Archerhub makes it easy for you to put in your order number
                online to retrieve the location of your shipment. In addition,
                automated emails will be sent to you about the progress of your
                shipment.
              </p>
            </Details>
          </div>
          <Link to="/carrier">
            <Button
              className="bg-yellow-500 hover:bg-yellow-700 active:bg-yellow-800 hover:text-yellow-100 focus:outline-none focus:shadow-outline text-yellow-800 md:font-semibold text-sm md:text-lg py-1 md:py-2 xl:py-4 px-4 md:px-8 mr-3 lg:mr-6 mb-6 rounded-full"
              type="button"
            >
              Haul with ArcherHub
            </Button>
          </Link>
          <Link to="/shipper">
            <Button
              className="bg-red-600 hover:bg-red-800 active:bg-red-900 hover:text-red-100 focus:outline-none focus:shadow-outline text-red-100 md:font-semibold text-sm md:text-lg py-1 md:py-2 xl:py-4 px-4 md:px-8 mr-3 lg:mr-6 rounded-full"
              type="button"
            >
              Ship with ArcherHub
            </Button>
          </Link>
        </div>
      </Layout>
    )
  }
}
export default Faq
